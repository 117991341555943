import styled from 'styled-components';
import { sizes } from '../../../styles/variables';

export const MainSearchTextInputWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;

  @media (max-width: 599px) {
      display: none;
  }
`;
