import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { ArrowBack } from '@material-ui/icons';

import { IconButtonUI } from '../../componentsUI/IconButton';
import { getUserInterface } from '../../utils/patientUserHospitalUtils';

export default () => {
  const { t } = useTranslation();
  const userInterface = getUserInterface();
  const backButtonTooltip = userInterface === 'patient' ? 'back.to.dashboard' : 'back.to.hospital';

  return (
    <IconButtonUI
      title={t(backButtonTooltip)}
      Icon={ArrowBack}
      handleClick={() => navigate('/dashboard')}
    />
  );
};

export const CancelButton = () => {
  const { t } = useTranslation();

  return (
    <IconButtonUI
      title={t('back')}
      Icon={ArrowBack}
      handleClick={() => window.history.back()}
    />
  );
};
