import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { publishablePlatformUpdates, displayUpdateNews, setLocalStorageReadNews } from '../../utils/PlatformUpdateUtils';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      height: '100vh',
      padding: 16,
      '& .MuiDialogTitle-root .MuiTypography-root': {
        fontWeight: 700,
        fontSize: '1.875rem',
      },
      '@media (max-width:991px)': {
        padding: 12,
        margin: 24,
      },
      '@media (max-width:599px)': {
        padding: 6,
        margin: 12,
        '& .MuiDialogTitle-root': {
          padding: '8px 12px',
        },
      },
    },
  },
  content: {
    marginTop: 4,
    '& ul > li': {
      marginBottom: 8,
      lineHeight: 1.05,
      '& span:nth-child(1)': {
        fontSize: '1.25rem',
        fontWeight: 700,
        color: theme.palette.primary.main,
      },
      '& span:nth-child(2)': {
        fontSize: '0.875em',
        color: theme.palette.grey.A200,
      },
    },
  },
  button: {
    padding: '10px 60px',
    fontWeight: 600,
  },
}));

export const PlatformNewsModal = () => {
  const dispatch = useDispatch();
  const setReadNews = () => dispatch({
    type: 'NEWS_ALREADY_READ',
    newsAlreadyRead: { newsAlreadyRead: true },
  });
  const { t } = useTranslation();
  const classes = useStyles();
  const [displayModal, setDisplayOpen] = useState(true);

  const newsAlreadyRead = useSelector((state) => state.userInterface.newsAlreadyRead);
  const open = displayModal && !newsAlreadyRead && displayUpdateNews;

  const closeModal = () => {
    setDisplayOpen(false);
    setReadNews();
    setLocalStorageReadNews();
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
    >
      <DialogTitle>{t('update.news.alma.health.platform')}</DialogTitle>
      <DialogContent>
        <Typography>{`${t('available.new.features')}:`}</Typography>
        <Grid container item spacing={2} xs={12} className={classes.content}>
          <ul>
            {publishablePlatformUpdates.map((newsItem) => (
              <li key={newsItem.id}>
                <Typography component="span">{`${newsItem.id}` }</Typography>
                <Typography component="span">
                  {` | ${newsItem.date.day} ${t(`${newsItem.date.monthIndex}`)} ${newsItem.date.year}`}
                </Typography>
                <br />
                {`${newsItem.description}.`}
              </li>
            ))}
          </ul>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={closeModal}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
