import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import {
  ClickAwayListener,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Zoom from '@material-ui/core/Zoom';
import {
  AccountBox,
  CardMembership,
  Email as EmailIcon,
  ExitToApp,
} from '@material-ui/icons';
import { isEmpty } from '../../utils/ObjectUtils';
import { getUserInterface } from '../../utils/patientUserHospitalUtils';
import { GET_USER_FOR_PREVIEW } from '../../graphql/queries';

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: 0,
  },
  large: {
    // margin: theme.spacing(1),
    cursor: 'pointer',
    width: theme.spacing(6),
    height: theme.spacing(6),
    border: '1px grey solid',
  },
  initials: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: '.9375em',
    cursor: 'pointer',
  },
}));

const UserIconTooltip = withStyles(() => ({
  tooltipPlacementTop: {
    margin: '4px 0',
  },
}))(Tooltip);

const UserAccountMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const UserAccountMenuItem = withStyles((theme) => ({
  root: {
    paddingRight: '3em',
    color: theme.palette.grey.A400,
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey.A100,
      fontSize: '.9375em',
      fontWeight: '700',
      padding: '.3125em 1.125em',
      borderTop: '1px solid #0000001F',
      borderBottom: '1px solid #0000001F',
    },
    '& .MuiListItemText-root': {
      marginTop: '6px',
    },
    '& .MuiListItemIcon-root': {
      // color: theme.palette.primary.main,
      '& > svg': {
        color: '#666',
        height: '1.125em',
        width: '1.125em',
      },
    },
    '&:hover': {
      // backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
        '& > svg': {
          color: theme.palette.primary.dark,
        },
      },
    },
  },
}))(MenuItem);

export default ({ linkTo, openUrl, logout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayTooltip, setTooltip] = useState(false);
  const { loading, data } = useQuery(GET_USER_FOR_PREVIEW);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleShowTooltip = () => { if (!anchorEl) setTooltip(true); };
  const handleHideTooltip = () => setTooltip(false);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => {
    setTooltip(false);
    setAnchorEl(event.currentTarget);
  };

  if (loading || isEmpty(data)) return null;
  const userInterface = getUserInterface();

  const user = data && data.user;
  const initials = user && (user.name.charAt(0) + user.surname.charAt(0)).toUpperCase();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <UserIconTooltip
        arrow
        title={t('user.profile')}
        TransitionComponent={Zoom}
        open={!anchorEl && displayTooltip}
        onOpen={handleShowTooltip}
        onClose={handleHideTooltip}
      >
        <IconButton
          variant="contained"
          aria-controls="user-profile"
          aria-haspopup="true"
          className={classes.icon}
          onClick={handleClick}
        >

          {data.user.profilePhoto ? (
            <Avatar
              alt="User"
              className={classes.large}
              src={data.user.profilePhoto}
            />
          ) : (
            <Avatar
              className={classes.initials}
              variant="circular"
            >
              {initials}
            </Avatar>
          )}
          <UserAccountMenu
            id="user-profille"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <UserAccountMenuItem disabled>{`${t('account')}:`}</UserAccountMenuItem>
            <UserAccountMenuItem onClick={() => linkTo('/user/settings/edit')}>
              <ListItemIcon>
                <AccountBox fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {t('my.profile')}
              </ListItemText>
            </UserAccountMenuItem>
            {userInterface === 'doctor' && (
              <UserAccountMenuItem onClick={() => linkTo('/user/settings/plan')}>
                <ListItemIcon>
                  <CardMembership fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  {t('user.plan')}
                </ListItemText>
              </UserAccountMenuItem>
            )}
            <Divider />
            <UserAccountMenuItem onClick={logout}>
              <ListItemIcon>
                <ExitToApp fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {t('log.out')}
              </ListItemText>
            </UserAccountMenuItem>
            <UserAccountMenuItem disabled>{`${t('help')}:`}</UserAccountMenuItem>
            <UserAccountMenuItem onClick={() => openUrl({
              url: 'https://alma-medical.com/en/contact/',
              target: '_blank',
              rel: 'noopener noreferrer',
            })}
            >
              <ListItemIcon>
                <EmailIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {t('contact')}
              </ListItemText>
            </UserAccountMenuItem>
            <Divider />
          </UserAccountMenu>
        </IconButton>
      </UserIconTooltip>
    </ClickAwayListener>
  );
};
