import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import i18n from 'i18next';
import { Tooltip, Zoom } from '@material-ui/core';
import { Description, Lock, LockOpen, InsertDriveFileOutlined } from '@material-ui/icons';
import {
  getFormattedDate,
  getFormattedDateTime,
  getDateTimeOption,
  getFromNowTime,
  getDateTimeFromNowTimeObject,
  getYears,
} from './dateTimeUtils';
import { ParseNotificationMessage } from './NotificationUtils';
import { getProtocolStateLabel } from './ProtocolUtils';
import functionStyles from './functionStyles';

export const DATEFORMAT = 'DATEFORMAT';
export const TIMEFORMAT = 'TIMEFORMAT';
export const DATETIME = 'DATETIME';
export const DATETIMEFORMAT = 'DATETIMEFORMAT';
export const DATETIMEOPTION = 'DATETIMEOPTION';
export const FROMNOW = 'FROMNOW';
export const YEARS = 'YEARS';
export const DATETIMEFROMNOWOBJECT = 'DATETIMEFROMNOWOBJECT';
// export const PROFILE = 'PROFILE';
export const PATIENTNAME = 'PATIENTNAME';
export const PERCENT = 'PERCENT';
export const MESSAGE = 'MESSAGE';
// export const MATERIAL = 'MATERIAL';
export const SIZE = 'SIZE';
export const SEPTOUPPER = 'SEPTOUPPER';
export const LENGTH = 'LENGTH';
export const PROTOCOLSTATE = 'PROTOCOLSTATE';
export const BOOLEAN = 'BOOLEAN';
export const ACTIVECASE = 'ACTIVECASE';
export const ACTIVECASE2 = 'ACTIVECASE2';
export const VIRTUALVISITREPLY = 'VIRTUALVISITREPLY';
export const LOCALE = 'LOCALE';
export const LOCALETOUPPER = 'LOCALETOUPPER';

function SepToUpper(value, sep) {
  let result = '';

  for (let i = 0; i < value.length; i++) {
    let isUpperCase = (i > 0 && value.charAt(i) === value.charAt(i).toUpperCase());
    result += (isUpperCase) ? sep + value.charAt(i) : value.charAt(i);
  }

  return result;
}

// export function Profile(value) {
//   const regex = new RegExp(/(?<=Profile)[\w]+/g);
//   return value && `${regex.exec(value)}`;
// }

// export function Material(value) {
//   const regex = new RegExp(/(?<=MaterialHP3DHR)[\w]+/g);
//   return value && `HP 3D HR ${regex.exec(value)}`;
// }

export function Size(value) {
  let size = parseInt(value, 10);
  let type = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  for (let i = 0; i < 5; i++) {
    if (size <= 1024) return `${size.toFixed(2)} ${type[i]}`;
    size /= 1024;
  }

  return value;
}

// export function ProfileObject(value) {
//   const regex = new RegExp(/(?<=Profile)[\w]+/g);
//   return value && `${regex.exec(value.Name)} (${value.ID})`.trim();
// }

export function PatientName(value) {
  return value && `${value.name} ${value.surname}`;
}

export function DateTime(value) {
  if (!value) return '';

  let date = new Date(value).toLocaleDateString();
  let time = new Date(value).toLocaleTimeString();

  return (date !== 'Invalid Date' && time !== 'Invalid Date')
    ? `${date} ${time}` : '';
}

export function GetActiveCase({ value }) {
  const { t } = useTranslation();
  const styles = functionStyles();
  const description = value ? t('case.open') : t('case.closed');
  return (
    <Tooltip title={description} TransitionComponent={Zoom} placement="bottom" arrow>
      {value
        ? <LockOpen />
        : <Lock className={styles.disabled} />}
    </Tooltip>
  );
}

export function GetVirtualVisitReply({ value }) {
  const { t } = useTranslation();
  const styles = functionStyles();
  const description = value ? t('protocol.completed.by.patient') : t('pending.to.answer');
  return (
    <Tooltip title={description} TransitionComponent={Zoom} placement="bottom" arrow>
      {value
        ? <Description />
        : <InsertDriveFileOutlined className={styles.disabled} />}
    </Tooltip>
  );
}

export function GetDefault(fields, id) {
  let field = fields.find((item) => item.id === id);
  if (field) {
    let find = field.props.array.find((item) => item.default === 'true');
    if (find) return find.id;
  }

  return undefined;
}

export function GetFormat(value, format) {
  const { t } = useTranslation();

  switch (format) {
    case SEPTOUPPER:
      return SepToUpper(value, ' ');
    // case PROFILE:
    //   return ProfileObject(value);
    // case MATERIAL:
    //   return Material(value);
    case MESSAGE:
      return ParseNotificationMessage(value);
    case SIZE:
      return Size(value);
    case DATETIME:
      return DateTime(value);
    case DATETIMEFORMAT:
      return getFormattedDateTime({ date: value });
    case DATETIMEOPTION:
      return getDateTimeOption({ date: value });
    case DATEFORMAT:
      return getFormattedDate({ date: value });
    case TIMEFORMAT:
      return new Date(value).toLocaleTimeString();
    case FROMNOW:
      return getFromNowTime({ date: value });
    case YEARS:
      return getYears(moment(value).utc().locale(i18n.language));
    case DATETIMEFROMNOWOBJECT:
      return getDateTimeFromNowTimeObject({ date: value });
    case PATIENTNAME:
      return PatientName(value);
    case PERCENT:
      return `${parseFloat(value).toFixed(2)} %`;
    case LENGTH:
      return value && value.length;
    case BOOLEAN:
      return value ? t('yes') : t('not');
    case ACTIVECASE:
      return value ? t('open.ed') : t('closed');
    case ACTIVECASE2:
      return GetActiveCase({ value });
    case VIRTUALVISITREPLY:
      return GetVirtualVisitReply({ value });
    case PROTOCOLSTATE:
      return t(getProtocolStateLabel(value));
    case LOCALE:
      return t(value.toLowerCase());
    case LOCALETOUPPER:
      return t(value.toLowerCase()).toUpperCase();
    default:
      return (value !== undefined) ? value : '';
  }
}

export function GetValueToPath(obj, path) {
  const split = path.replace(/\[/g, '.').replace(/]/g, '').split('.');
  let value = obj;

  split.forEach((key) => {
    if (value) value = value[key];
  });

  return value;
}

export function GetValue(item, field) {
  const split = field.id.split('.');
  let value = item;
  split.forEach((key) => {
    if (value) value = value[key];
  });

  value = (value && value !== null) ? value : '';

  return GetFormat(value, field.format);
}

// eslint-disable-next-line no-underscore-dangle
export const GetItemType = (item) => item.__typename;
