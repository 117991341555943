import React, { useState } from 'react';

import '../i18n';
import '../styles/global.css';
import '../../node_modules/bootstrap-utilities/bootstrap-utilities.css';

import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';
import { useKeycloak } from 'react-keycloak';

import { GET_USER_UUID } from '../graphql/queries';
import Loading from './Common/Loading';
import { isEmpty } from '../utils/ObjectUtils';
import { networkErrorParse } from '../utils/ErrorGraphQLUtils';

import { getUserInterface } from '../utils/patientUserHospitalUtils';
import AppDoctors from './AppDoctors';
import AppPatients from './AppPatients';
import { GraphQLAlertUI } from '../componentsUI/Alert';
import { EmptyPage } from './Common/styled/EmptyPage';
import { AppContainer } from './Common/styled/AppContainer';

export default ({ children }) => {
  const dispatch = useDispatch();
  const [requestError, setRequestError] = useState(null);
  const user = useSelector((state) => state.userInterface.user);
  const { loading: userLoading, data: userData, error: userError, refetch: userRefetch } = useQuery(GET_USER_UUID);
  const [keycloak, initialized] = useKeycloak();

  const userInterface = getUserInterface();
  const redirectDomain = userInterface === 'patient'
    ? process.env.GATSBY_APP_TELEMEDICINE_URL
    : process.env.GATSBY_APP_CLOUD_URL;

  // Keycloak validation
  if (!initialized) {
    return <div>Loading...</div>;
  }

  if (!keycloak.authenticated) {
    keycloak.login({ redirectUri: `${redirectDomain}${window.location.pathname}${window.location.search}` });
    return <div>Loading...</div>;
  }

  if (!keycloak.tokenParsed.roles.includes('https://api.cloud.alma-medical.com/access')) {
    return <div>You don&apos;t have the permission to access the platform</div>;
  }

  if (userError && !userData && userError !== requestError) {
    setRequestError(userError);
  }

  if (requestError && userData && userError !== requestError) {
    setRequestError(userError);
  }

  const errorMessage = networkErrorParse(requestError);

  if (errorMessage) {
    return (
      <AppContainer>
        <EmptyPage>
          <GraphQLAlertUI severity="error" title="Error" refetch={userRefetch}>
            {errorMessage}
          </GraphQLAlertUI>
        </EmptyPage>
      </AppContainer>
    );
  }

  if (userLoading || isEmpty(userData)) {
    return <Loading />;
  }

  if (userData && !user) {
    dispatch({ type: 'SET_USER', user: userData });
  }

  if (userInterface === 'doctor') {
    return <AppDoctors>{children}</AppDoctors>;
  }

  if (userInterface === 'patient') {
    return <AppPatients>{children}</AppPatients>;
  }

  return <div>No environment configured</div>;
};
