// @flow

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { Typography } from '@material-ui/core';

import { useMinWidth } from '../../hooks/useResize';
import { sizes } from '../../styles/variables';

import { Header, HeaderGroup } from './styled/Header';
import { Notifications } from './Notifications';
import LanguageSelector from './LanguageSelector';
import UserAccount from './UserAccount';
import MainSearch from './MainSearch';
import BackButton, { CancelButton } from './BackButton';
import { BackButtonWrapper } from './styled/BackButtonWrapper';
import { PlatformNewsModal } from '../PlatformNews/PlatformNewsModal';
import { IconButtonUI } from '../../componentsUI/IconButton';

const useStyles = makeStyles((theme) => ({
  title: {
    marginRight: 'auto',
    '& .MuiTypography-h2': {
      padding: '5px 10px',
      fontFamily: 'Muli',
      fontSize: '2.3rem',
      fontWeight: 700,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.875rem',
      },
    },
    '@media (max-width:768px)': {
      display: 'none',
    },
  },
}));

export default ({ type, fixSidebar }) => {
  const currentHospital = useSelector((state) => state.hospital);
  const currentProvider = useSelector((state) => state.provider);
  const dispatch = useDispatch();
  const isMinWidth = useMinWidth(sizes.mdMin);
  const classes = useStyles();

  const showSidebar = () => dispatch({
    type: 'SET_VISIBILITY_FILTER',
    filter: { visibleSidebar: true },
  });

  const handleHome = () => {
    navigate('/dashboard');
  };

  const getHeader = () => {
    let header;
    switch (type) {
      case 'provider':
        header = <div />;
        break;
      case 'new':
        header = <BackButtonWrapper><CancelButton /></BackButtonWrapper>;
        break;
      case 'settings':
      case 'userSettings':
        header = <BackButtonWrapper><BackButton /></BackButtonWrapper>;
        break;
      default:
        header = <MainSearch />;
        break;
    }
    return header;
  };

  return (
    <Header fixSidebar={fixSidebar}>
      {(!fixSidebar || isMinWidth) && (
        <>
          <IconButtonUI
            title="Menu"
            Icon={MenuIcon}
            handleClick={showSidebar}
          />
          <div className={classes.title} onClick={handleHome} aria-hidden="true">
            <Typography variant="h2">
              {type === 'provider' ? currentProvider.name : currentHospital.name}
            </Typography>
          </div>
        </>
      )}
      {getHeader()}
      <HeaderGroup>
        <LanguageSelector />
        {type !== 'provider' && <Notifications />}
        <UserAccount />
      </HeaderGroup>
      <PlatformNewsModal />
    </Header>
  );
};
