const parseJson = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return null;
  }
};

export const getLocalStorageJson = (key) => (parseJson(localStorage.getItem(key)));
export const getSessionStorageJson = (key) => (parseJson(sessionStorage.getItem(key)));
