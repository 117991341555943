import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 200,
    float: 'right',
    textTransform: 'none',
    fontFamily: 'Muli',
    fontSize: '1.1875em',
    fontWeight: '600',
    textShadow: '1px 1px 2px #0002',
    border: `1px solid ${theme.palette.primary.main}27`,
  },
  root: {
    '& .MuiButton-containedSizeLarge': {
      padding: '.25em 1.25em',
    },
  },
}));

export const ButtonUI = ({ type, size, color, variant, disabled = false, children, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      type={type}
      size={size}
      color={color}
      className={`${classes.button} MuiPaper-elevation1`}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default ButtonUI;
