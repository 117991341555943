import { AbilityBuilder } from '@casl/ability';

const subjectName = (subject) => (
  // eslint-disable-next-line no-underscore-dangle
  !subject || typeof subject === 'string' ? subject : subject.__typename
);

export default (user, hospitalData) => AbilityBuilder.define({ subjectName }, (can, cannot) => {
  // Get all contributor uuids for all hospital user groups
  const contributorUuids = [];
  const userGroups = hospitalData && hospitalData.hospital && hospitalData.hospital.userGroups;
  const groupMembers = userGroups && userGroups.map((group) => group.groupMembers).filter((members) => members.length);
  groupMembers.forEach((members) => {
    members.map((member) => contributorUuids.push(member.user.uuid));
  });
  const userIsContributor = contributorUuids.includes(user.sub);

  // Hospital
  // can(['manage', 'edit', 'delete'], 'Hospital', { ownerUser: { uuid: user.sub } });
  can(['manage', 'edit', 'delete'], 'Hospital', { owner: user.sub });
  can('manage', 'Hospital', {
    teamMembers: {
      $elemMatch: {
        'user.uuid': user.sub,
        role: 'MANAGER',
      },
    },
  });
  if (userIsContributor) {
    can('manage', 'Hospital', {
      userGroups: {
        $elemMatch: {
          groupMembers: {
            $elemMatch: {
              'user.uuid': user.sub,
              role: 'MANAGER',
            },
          },
        },
      },
    });
  }

  // Patient
  can(['view', 'edit', 'delete'], 'Patient', { createdBy: user.sub });
  // can(['view', 'edit', 'delete'], 'Patient', { 'hospital.owner': user.sub });
  can(['view', 'edit'], 'Patient', {
    medicalCases: {
      $elemMatch: {
        collaborations: {
          $elemMatch: {
            'user.uuid': user.sub,
            permission: 'CONTRIBUTE',
          },
        },
      },
    },
  });
  can('view', 'Patient', { 'hospital.owner': user.sub });
  can('view', 'Patient', {
    medicalCases: {
      $elemMatch: {
        collaborations: {
          $elemMatch: {
            'user.uuid': user.sub,
            permission: 'VIEW',
          },
        },
      },
    },
  });

  // Study
  can('delete', 'DicomStudy', { owner: user.sub });
  can('delete', 'DicomStudy', { 'medicalCase.createdBy': user.sub });
  can('delete', 'DicomStudy', {
    'medicalCase.collaborations': {
      $elemMatch: {
        'user.uuid': user.sub,
        permission: 'CONTRIBUTE',
      },
    },
  });

  if (userIsContributor) {
    can('delete', 'DicomStudy', {
      'medicalCase.groupCollaborations': {
        $elemMatch: {
          'group.uuid': { $in: userGroups.map((group) => group.uuid) },
          permission: 'CONTRIBUTE',
        },
      },
    });
  }

  // Medical case
  can(['contribute', 'edit', 'delete'], 'MedicalCase', { createdBy: user.sub });
  can('contribute', 'MedicalCase', {
    collaborations: {
      $elemMatch: {
        'user.uuid': user.sub,
        permission: 'CONTRIBUTE',
      },
    },
  });

  if (userIsContributor) {
    can('contribute', 'MedicalCase', {
      groupCollaborations: {
        $elemMatch: {
          permission: 'CONTRIBUTE',
          'group.uuid': { $in: userGroups.map((group) => group.uuid) },
        },
      },
    });
  }

  cannot('contribute', 'MedicalCase', {
    collaborations: {
      $elemMatch: {
        'user.uuid': user.sub,
        permission: 'VIEW',
      },
    },
  });
});
