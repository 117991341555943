/*
Mutations for:
------
Case
Collaborations
Groups
Hospitals
Notifications
Patients
Studies
Teams
User
User Group
User Group Member
*/

import gql from 'graphql-tag';

// Case
export const ADD_MEDICAL_CASE_COLLABORATION = gql`
  mutation AddMedicalCaseCollaboration($input: AddMedicalCaseCollaborationInput!) {
    addMedicalCaseCollaboration(input: $input) {
      uuid
      collaborations {
        uuid
        permission
        user {
          uuid
          name
          surname
          email
        }
      }
    }
  }
`;

export const ADD_MEDICAL_CASE_GROUP_COLLABORATION = gql`
  mutation AddMedicalCaseGroupCollaboration($input: AddMedicalCaseGroupCollaborationInput!) {
    addMedicalCaseGroupCollaboration(input: $input) {
      uuid
      collaborations {
        uuid
        permission
        user {
          uuid
          name
          surname
          email
        }
      }
    }
  }
`;

export const CREATE_MEDICAL_CASE = gql`
  mutation createMedicalCase($medicalCase: CreateMedicalCaseInput!) {
    createMedicalCase(input: $medicalCase) {
      uuid
    }
  }
`;

export const UPDATE_MEDICAL_CASE = gql`
  mutation UpdateMedicalCase($medicalCase: UpdateMedicalCaseInput!) {
    updateMedicalCase(input: $medicalCase) {
      uuid
      title
      description
      specialty
      subspecialty
      active
    }
  }
`;

export const DELETE_MEDICAL_CASE = gql`
  mutation ($medicalCaseUuid: ID!) {deleteMedicalCase(medicalCaseUuid: $medicalCaseUuid)}
`;

export const ASSIGN_MEDICAL_CASE_COLLABORATION = gql`
  mutation ($uuid: ID!) {
    assignMedicalCaseCollaboration(medicalCaseCollaborationUuid: $uuid) {
      uuid
    }
  }
`;

export const ADD_MEDICAL_CASE_COMMENT = gql`
  mutation ($input: AddMedicalCaseCommentInput!) {
    addMedicalCaseComment(input: $input) {
      uuid
      title
      description
      specialty
      createdBy
      createdAt
      patient {
        uuid
        name
        surname
      }
      studies {
        uuid
        title
        createdAt
        numberOfSeries
        numberOfInstances
        modalities
      }
      comments {
        uuid
        body
        createdAt
        author {
          uuid
          name
          surname
          profilePhoto
        }
      }
    }
  }
`;

// mutation createMedicalCaseFile($medicalCaseFile: CreateMedicalCaseFileInput!) {
//   createMedicalCaseFile(input: $medicalCaseFile) {
//     uuid
//     file
//     fileName
//     fileSize
//   }
// }
export const CREATE_MEDICAL_CASE_FILE = gql`
    mutation ($input: CreateMedicalCaseFileInput!) {
        createMedicalCaseFile(input: $input) {
            uuid
            file
        }
    }
`;

export const CREATE_PATIENT_FILE = gql`
    mutation ($input: CreatePatientFileInput!) {
        createPatientFile(input: $input) {
            uuid
            file
        }
    }
`;

// Collaborations
export const SET_COLLABORATION_PERMISSION = gql`
  mutation SetCollaborationPermission($input: SetMedicalCaseCollaborationPermissionInput!) {
    setMedicalCaseCollaborationPermission(input: $input) {
      uuid
      permission
      userEmail
      user {
        uuid
        name
        surname
        email
        profilePhoto
      }
    }
  }
`;

export const SET_GROUP_COLLABORATION_PERMISSION = gql`
  mutation SetGroupCollaborationPermission($input: SetMedicalCaseGroupCollaborationPermissionInput!) {
    setMedicalCaseGroupCollaborationPermission(input: $input) {
      uuid
      permission
      group {
        uuid
        name
      }
    }
  }
`;

// Groups
export const DELETE_COLLABORATION = gql`
  mutation ($input: ID!) {
    deleteMedicalCaseCollaboration(medicalCaseCollaborationUuid: $input)
  }
`;

export const DELETE_GROUP_COLLABORATION = gql`
  mutation ($input: ID!) {
    deleteMedicalCaseGroupCollaboration(medicalCaseGroupCollaborationUuid: $input)
  }
`;

// Hospital
export const CREATE_HOSPITAL_WITHOUT_LOGO = gql`
  mutation ($hospitalInput: CreateHospitalInput!) {
    createHospital(input: $hospitalInput) {
      uuid
      name
      streetAddress
      postalCode
      city
      country
      mainColor
    }
  }
`;

export const CREATE_HOSPITAL_WITH_LOGO = gql`
  mutation ($hospitalInput: CreateHospitalInput!) {
    createHospital(input: $hospitalInput) {
      uuid
      name
      streetAddress
      postalCode
      city
      country
      mainColor
      logo
      availableAdvancedTools {
        uuid
      }
      menuOptionAIStats
      menuOptionStudies
      menuOptionMedicalCases
      menuOptionPatients
      menuOptionGroups
      menuOptionProtocols
      menuOptionVirtualVisits
      menuOptionFiles
      menuOptionSettings
    }
  }
`;

export const UPDATE_HOSPITAL = gql`
  mutation($updateHospitalInput: UpdateHospitalInput!) {
    updateHospital(input: $updateHospitalInput) {
      uuid
      name
      country
      streetAddress
      postalCode
      city
      country
      mainColor
      logo
      menuOptionAIStats
      menuOptionStudies
      menuOptionMedicalCases
      menuOptionPatients
      menuOptionGroups
      menuOptionProtocols
      menuOptionVirtualVisits
      menuOptionFiles
      menuOptionSettings
    }
  }
`;

export const DELETE_HOSPITAL = gql`
    mutation ($hospitalUuid: ID!) {deleteHospital(hospitalUuid: $hospitalUuid)}
`;

export const CREATE_HOSPITAL_FILE = gql`
    mutation ($input: CreateHospitalFileInput!) {
        createHospitalFile(input: $input) {
            uuid
            file
        }
    }
`;

export const DELETE_HOSPITAL_FILE = gql`
  mutation ($uuid: ID!) {deleteHospitalFile(hospitalFileUuid: $uuid)}
`;

export const DELETE_PATIENT_FILE = gql`
  mutation ($uuid: ID!) {deletePatientFile(patientFileUuid: $uuid)}
`;

export const DELETE_MEDICAL_CASE_FILE = gql`
  mutation ($uuid: ID!) {deleteMedicalCaseFile(medicalCaseFileUuid: $uuid)}
`;

export const DELETE_STUDY_REPORT = gql`
  mutation ($uuid: ID!) {deleteReport(reportUuid: $uuid)}
`;

// Notifications

export const READ_NOTIFICATION = gql`
  mutation ($uuid: ID!) {
    readNotification(uuid: $uuid) {
      uuid
      hospital {
        uuid
        name
        streetAddress
        postalCode
        city
        country
        mainColor
        owner
      }
    }
  }
`;

// Patients

export const ADD_PATIENT = gql`
  mutation AddPatient($patient: CreatePatientInput!) {
    createPatient(input: $patient) {
      uuid
      name
      surname
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient($patient: UpdatePatientInput!) {
    updatePatient(input: $patient) {
      uuid
      name
      surname
      birthDate
      customId
      email
      identityNumber
      nationality
      insurance
      insuranceNumber
      phone
      address
      gender
    }
  }
`;

export const DELETE_PATIENT = gql`
  mutation ($patientUuid: ID!) {deletePatient(patientUuid: $patientUuid)}
`;

// User Patient Uuid
export const UPDATE_USER_PATIENT_UUID = gql`
  mutation UpdateUserPatientUuid($patientUuid: ID!) {
    updateUserPatientUuid(patientUuid: $patientUuid) {
      uuid
      patientUuid
    }
  }
`;

// Studies

export const CREATE_DICOM_STUDY = gql`
  mutation ($dicomInput: CreateDicomStudyInput!) {
    createDicomStudy(input: $dicomInput) {
      uuid
      title
      description
      medicalCase {
        uuid
        title
        patient {
          uuid
          name
          surname
          hospital {
            uuid
            name
          }
        }
      }
      hospital {
        uuid
        name
      }
      studyInstanceUid
      numberOfSeries
      numberOfInstances
      modalities
      storageSize
      haveInstance
    }
  }
`;

export const DELETE_DICOM_STUDY = gql`
  mutation ($uuid: ID!) {
    deleteDicomStudy(dicomStudyUuid: $uuid)
  }
`;

export const EDIT_DICOM_STUDY_INFO = gql`
    mutation updateDicomStudy($dicomStudy: UpdateDicomStudyInput!) {
        updateDicomStudy(input: $dicomStudy) {
            uuid
            title
            description
        }
    }
`;

/** {
  "deleteInput": {
    "uuid": ID
    "studyInstanceUid": String
  } */
export const DELETE_PACS_DICOM_STUDY = gql`
  mutation ($deleteInput: SearchDicomStudyInput!) {
    deletePacsDicomStudy(input: $deleteInput) {
      uuid
      studyInstanceUid
    }
  }
`;

export const DELETE_FULL_DICOM_STUDY = gql`
  mutation ($deleteInput: SearchDicomStudyInput!) {
    deleteFullDicomStudy(input: $deleteInput) {
      uuid
      studyInstanceUid
    }
  }
`;

// method not used
export const DELETE_HOSPITAL_STUDY = gql`
    mutation ($uuid: ID!) {
        deleteHospitalStudy(hospitalStudyUuid: $uuid)
    }
`;

export const DOWNLOAD_DICOM_STUDY = gql`
  mutation ($uuid: ID!) {
    dicomStudyDownload(uuid: $uuid) {
      uuid
      title
      owner
      createdAt
      updatedAt
      description
      medicalCase {
        uuid
        title
        patient {
          uuid
          name
          surname
        }
      }
      hospital {
        uuid
      }
      studyInstanceUid
      numberOfSeries
      numberOfInstances
      modalities
      reports {
        uuid
        status
        createdAt
        updatedAt
        advancedTool {
          uuid
          name
        }
      }
    }
  }
`;

// Team

export const ADD_HOSPITAL_TEAM_MEMBER = gql`
  mutation ($input: AddTeamMemberToHospitalInput!) {
    addTeamMemberToHospital(input: $input) {
      uuid
      user {
        uuid
        name
        surname
        email
      }
      createdAt
      role
    }
  }
`;

/* eslint-disable no-param-reassign */
export const SET_TEAM_MEMBER_ROLE = gql`
  mutation ($input: SetHospitalTeamMemberRoleInput!) {
    setHospitalTeamMemberRole(input: $input) {
        user {
          uuid
          name
          surname
          email
        }
        role
        uuid
    }
  }
`;

export const DELETE_HOSPITAL_TEAM_MEMBER = gql`
  mutation ($input: ID!) {
    deleteHospitalTeamMember(hospitalTeamMemberUuid: $input)
  }
`;

// User

export const UPDATE_USER = gql`
  mutation UpdateUser($userParams: UpdateUserInput!) {
    updateUser(input: $userParams) {
      uuid
      name
      surname
      personalId
      professionalRole
      specialty
      subspecialty
      collegiateNumber
      profilePhoto
    }
  }
`;

// User Group
export const CREATE_USER_GROUP = gql`
  mutation ($input: CreateUserGroupInput!) {
    createUserGroup(input: $input) {
      uuid
      name
      createdBy
    }
  }
`;

export const UPDATE_USER_GROUP = gql`
  mutation ($input: UpdateUserGroupInput!) {
    updateUserGroup(input: $input) {
      uuid
      name
      createdBy
    }
  }
`;

export const DELETE_USER_GROUP = gql`
  mutation ($userGroupUuid: ID!) {
    deleteUserGroup(userGroupUuid: $userGroupUuid)
  }
`;

// User Group Member
export const CREATE_USER_GROUP_MEMBER = gql`
  mutation ($input: CreateUserGroupMemberInput!) {
    createUserGroupMember(input: $input) {
      uuid
      userUuid
      userGroup {
        uuid
        name
        createdBy
      }
    }
  }
`;

export const DELETE_USER_GROUP_MEMBER = gql`
  mutation ($userGroupMemberUuid: ID!) {
    deleteUserGroupMember(userGroupMemberUuid: $userGroupMemberUuid)
  }
`;

export const CREATE_USER = gql`
  mutation ($input: CreateUserInput!) {
    createUser(input: $input) {
      uuid
      name
      surname
      email
      personalId
      adAcceptance
      professionalRole
      specialty
      subspecialty
      collegiateNumber
      profilePhoto
      patientUuid
    }
  }
`;

export const INVITE_PATIENT = gql`
  mutation ($patientUuid: ID!, $userEmail: String!, $language: String) {
    invitePatient(patientUuid: $patientUuid, userEmail: $userEmail, language: $language) {
        uuid
        email
    }
  }
`;

export const SIGN_NEW_USER_PATIENT_UUID = gql`
  mutation SignNewUserPatientUuid($patientUuid: ID!, $userUuid: ID!, $signature: String!, $email: String!) {
    signNewUserPatientUuid(patientUuid: $patientUuid, userUuid: $userUuid, signature: $signature, email: $email) {
      uuid
      patientUuid
    }
  }
`;

export const CHANGE_BILLING_PLAN = gql`
  mutation ($input: ChangeBillingPlanInput!) {
    changeBillingPlan(input: $input) {
      uuid
      userUuid
      consumedUsers
      consumedStorage
      consumedCredits
      consumedSupportHours
      addonPaymentsPayerRef
      addonPaymentsCardRef
      cardLastNumbers
      nextBillingDate
      countryCode
      province
      town
      postalCode
      address
      createdAt
      updatedAt
      billingPlan {
        uuid
        name
        price
        description
        users
        storage
        credits
        supportHours
        createdAt
        updatedAt
      }
      nextBillingPlan {
        uuid
        name
        price
      }
    }
  }
`;
