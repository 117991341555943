import React from 'react';

import { useQuery } from '@apollo/react-hooks';
import { useKeycloak } from 'react-keycloak';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

import { UserAccountWrapper, UserAccountLabel } from './styled/UserAccountStyles';
import { HighlightedText } from '../Common/styled/HighlightedText';
import { isEmpty } from '../../utils/ObjectUtils';
import { getUserInterface } from '../../utils/patientUserHospitalUtils';
import { GET_USER_BILLING_PLAN, GET_USER_FOR_PREVIEW } from '../../graphql/queries';
import UserAccountPopup from './UserAccountPopup';

export default () => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(GET_USER_FOR_PREVIEW);
  const { data: plan } = useQuery(GET_USER_BILLING_PLAN);
  const [keycloak] = useKeycloak();

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    keycloak.logout({ redirectUri: process.env.GATSBY_APP_BASE_URL });
  };

  const linkTo = (uri) => {
    navigate(uri);
  };

  const openUrl = ({ url, target, rel }) => {
    const redirect = window.open(url, target, rel);
    if (redirect) redirect.focus();
  };

  if (loading || isEmpty(data)) return null;

  const userInterface = getUserInterface();
  const planDescription = plan && plan.userBillingPlan ? plan.userBillingPlan.billingPlan.name : 'No plan';
  const userDescription = userInterface === 'patient' ? t('patient') : planDescription;

  return (
    <UserAccountWrapper>
      <UserAccountLabel>
        {`${data.user.name} ${data.user.surname}`}
        <br />
        <HighlightedText>{userDescription}</HighlightedText>
      </UserAccountLabel>
      <UserAccountPopup linkTo={linkTo} openUrl={openUrl} logout={logout} />
    </UserAccountWrapper>
  );
};
