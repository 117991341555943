import styled from 'styled-components';

export const ToastStyles = styled.div`
  .toast-progress {
    background: ${(props) => props.theme.colors.gray400};
  }

  .toast-info {
    padding: 14px;
    color: ${(props) => props.theme.colors.infoDark};
    background-color: ${(props) => props.theme.colors.infoLight};

    .toast-progress {
      background: ${(props) => props.theme.colors.infoMain};
    }
  }

  .toast-success {
    padding: 14px;
    color: ${(props) => props.theme.colors.successDark};
    background-color: ${(props) => props.theme.colors.successLight};

    .toast-progress {
      background: ${(props) => props.theme.colors.successDark};
    }
  }

  .toast-error {
    padding: 14px;
    color: ${(props) => props.theme.colors.errorDark};
    background-color: ${(props) => props.theme.colors.errorLight};

    .toast-progress {
      background: ${(props) => props.theme.colors.errorDark};
    }
  }
`;
