import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { alpha, withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { canI } from '../../casl/Can';

const ListItemStyles = withStyles((theme) => ({
  root: {
    '& .MuiListItemIcon-root': {
      color: alpha(theme.palette.primary.main, 0.75),
      minWidth: '48px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '2.85rem',
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Muli',
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    '&$selected': {
      '& .MuiTypography-body1': {
        fontWeight: 800,
      },
      '& .MuiListItemIcon-root': {
        color: alpha(theme.palette.primary.main, 0.84),
      },
    },
    '&$selected:hover': {
      // backgroundColor: 'purple',
      // color: 'white',
    },
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: alpha(theme.palette.primary.main, 0.84),
      },

    },
  },
  selected: {},
}))(ListItem);

export default (({ options, fixSidebar }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isBrowser = () => typeof window !== 'undefined';
  const hospital = useSelector((state) => state.hospital);
  const [pathname, setPathname] = useState(isBrowser() && window.location.pathname);

  const onClick = (option, e) => {
    if (option.disabled) {
      e.preventDefault();
    }
    dispatch({ type: 'SET_VISIBILITY_FILTER', filter: { visibleSidebar: false, visibleWorkspaces: false } });

    const timer = (fixSidebar) ? 0 : 200;
    setTimeout(() => navigate(option.link), timer);
    setPathname(option.matcher);
  };

  const canView = (item, entity) => (!item.can || canI(item.can, entity));
  const isAvailable = (item) => (!item.available || item.available(hospital));

  return (
    <List>
      {options.menuOptions
        .filter((option) => option && canView(option, hospital) && isAvailable(option))
        .map((option, key) => (

          option && (
            <div key={key.toString()}>
              <ListItemStyles
                button
                key={key.toString()}
                onClick={(e) => { onClick(option, e); }}
                selected={pathname.includes(option.matcher)}
                disabled={option.disabled}
              >
                {option.icon2 && (
                  <ListItemIcon><option.icon2 fontSize="large" /></ListItemIcon>
                )}

                <ListItemText primary={t(option.label)} />
              </ListItemStyles>
            </div>
          )

        ))}
    </List>
  );
});
