import styled, { css } from 'styled-components';

import { alpha, makeStyles } from '@material-ui/core/styles';
import { Button } from './Button';

const Base = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: ${(props) => (props.padding ? props.padding : props.theme.cardPadding)};
  cursor: ${(props) => (props.cursor ? props.cursor : 'auto')};
  ${(props) => !props.noBorder && css`
    border-bottom: ${props.theme.cardBorder};
  `}

  @media (hover: hover) {
    ${Button} {
      transition: ${(props) => props.theme.transition};
      opacity: ${(props) => (props.isUncollapsed ? '100' : '0')};
    }

    :hover {
      ${Button} {
        opacity: 1;
      }
    }
  }
`;

export const PaddedCard = styled(Base)``;

export const Card = styled(Base)`
  box-shadow: ${(props) => (props.noShadow ? 'none' : props.theme.baseBoxShadow)};
  border: ${(props) => (props.selected ? `solid 1px ${props.theme.colors.primary}` : '')};
`;

export const CardDialog = styled(Base)`
  padding: 8px 4px;
  box-shadow: ${(props) => (props.noShadow ? 'none' : props.theme.baseBoxShadow)};
  border: ${(props) => (props.selected ? `solid 1px ${props.theme.colors.primary}` : '')};
`;

export const SlimCard = styled(Card)`
  padding: ${(props) => (props.padding ? props.padding : '20px 18px')};
`;

export const useCardStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 8,
    padding: '.5em 1.25em',
    border: 'solid 1px #d0d0d07f',
    borderRadius: 8,
    backgroundColor: '#fffa',
    boxShadow: '#0000002a 1px 2px 3px',
    '&:hover': {
      boxShadow: '#0000002a 1px 3px 4px',
    },
    '& a': {
      color: alpha(theme.palette.text.primary, 0.5),
      '& svg > path': {
        fill: alpha(theme.palette.text.primary, 0.5),
        transition: 'fill .35s',
      },
      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.primary.dark,
        transition: 'color .35s',
        '& svg': {
          color: theme.palette.primary.main,
          transition: 'color .35s',
          '& > path': {
            fill: theme.palette.primary.main,
            transition: 'fill .35s',
          },
        },
      },
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxSizing: 'border-box',
    '& svg': {
      fontSize: '2.25em',
      marginRight: '12px',
      transition: 'color .35s',
    },
  },
  label: {
    margin: 0,
    fontWeight: 600,
  },
  date: {
    margin: 0,
    fontSize: '.875em',
    color: alpha(theme.palette.common.black, 0.6),
  },
}));
