export const MenuOptionLists = {
  hospital: {
    main:
      [
        'dashboard',
        'advancedToolStats',
        'studies',
        'patients',
        'cases',
        'virtualVisits',
        'protocols',
        'groups',
        'files',
        'settings',
      ],
    settings: [
      'general',
      'menu',
      'team',
      'group',
      'patients',
      'back',
    ],
  },
  patient: {
    main: [
      'dashboard',
      'cases',
      'virtualVisits',
    ],
  },
  provider: {
    main: [
      'advancedTools',
    ],
  },
  user: {
    doctor: [
      'profile',
      'plan',
      'back',
    ],
    patient: [
      'profile',
      'back',
    ],
  },
};
