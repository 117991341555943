import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'react-keycloak';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import Loading from '../components/Common/Loading';

const useStyles = makeStyles(() => ({
  errors: {
    '& > div': {
      margin: '5px 10px',
    },
    '& .MuiAlert-message': {
      width: 'calc(100% - 25px)',
    },
  },
  container: {
    width: '100%',
    maxWidth: 768,
    marginRight: 'auto',
    marginLeft: 'auto',
    borderRadius: 6,
    boxShadow: '0 4px 6px #0003',
  },
  wrapper: {
    margin: 24,
    borderRadius: 6,
    boxShadow: '0 4px 6px #0003',
  },
}));

const invalidGrantMessageOptions = [
  'invalid_grant: Stale token',
  'invalid_grant: Token is not active',
  'Token is not active',
  'Stale token',
  'Error in Keycloak User request',
];

export const isKeycloakBackendError = (string) => invalidGrantMessageOptions.includes(string.trim());

export const AlertWrapperUI = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {children}
    </Box>
  );
};

export const AlertContainerUI = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {children}
    </Box>
  );
};

export const AlertUI = ({ title, severity, children }) => {
  const classes = useStyles();

  return (
    <Alert severity={severity} className={classes.errors}>
      <AlertTitle><strong>{title}</strong></AlertTitle>
      {children}
    </Alert>
  );
};

export const GraphQLAlertUI = ({ title, severity, children, refetch }) => {
  const { t } = useTranslation();
  const [keycloak] = useKeycloak();
  const [retries, setRetries] = useState(0);

  const refreshBackend = () => {
    fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/command/cache:clear`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then((r) => {
        if (r.status === 200) {
          refetch();
          return;
        }
        setRetries(1);
      })
      .catch((e) => {
        console.warn(e);
        setRetries(1);
      });
  };

  if (retries === 0 && isKeycloakBackendError(children)) {
    refreshBackend();
    return (
      <>
        <AlertUI title="Info" severity="info">
          <Typography>{t('server.error.retrying.connection')}</Typography>
          <Typography>{t('please.wait')}</Typography>
        </AlertUI>
        <Loading />
      </>
    );
  }

  const message = children.split('invalid_grant: ').filter((i) => i);

  return <AlertUI title={title} severity={severity}>{t(message[0])}</AlertUI>;
};
