import {
  AssignmentInd,
  AssignmentOutlined,
  BurstMode,
  Event,
  FolderSharedOutlined,
  HomeWorkTwoTone,
  InsertDriveFileTwoTone,
  Memory,
  NoteTwoTone,
  Settings,
  SupervisedUserCircleOutlined,
} from '@material-ui/icons';

const advancedToolStatsAvailable = (elem) => (
  elem.menuOptionAIStats && elem.availableAdvancedTools && elem.availableAdvancedTools.length
);

export const MenuOptionObjects = {
  hospital: {
    main: {
      dashboard: {
        label: 'dashboard',
        link: '/dashboard',
        matcher: 'dashboard',
        icon: {
          name: 'dashboard',
        },
        icon2: HomeWorkTwoTone,
      },
      cases: {
        label: 'cases',
        link: '/cases',
        matcher: 'case',
        icon: {
          name: 'cases',
        },
        icon2: FolderSharedOutlined,
        available: ((elem) => elem.menuOptionMedicalCases),
      },
      patients: {
        label: 'patients',
        link: '/patients',
        matcher: 'patient',
        icon: {
          name: 'patient',
        },
        icon2: AssignmentInd,
        available: ((elem) => elem.menuOptionPatients),
      },
      groups: {
        label: 'groups',
        link: '/groups',
        matcher: 'groups',
        icon: {
          name: 'group',
        },
        icon2: SupervisedUserCircleOutlined,
        available: ((elem) => elem.menuOptionGroups),
      },
      protocols: {
        label: 'protocols',
        link: '/protocols',
        matcher: 'protocols',
        icon: {
          name: 'file-text',
        },
        icon2: AssignmentOutlined,
        available: ((elem) => elem.menuOptionProtocols),
      },
      virtualVisits: {
        label: 'virtual.visits',
        link: '/virtual-visits',
        matcher: 'virtual-visit',
        icon: {
          name: 'calendar',
        },
        icon2: Event,
        available: ((elem) => elem.menuOptionVirtualVisits),
      },
      advancedToolStats: {
        label: 'ai.stats',
        link: '/advanced-tools/stats',
        matcher: 'advanced-tool',
        icon: {
          name: 'memory',
        },
        icon2: Memory,
        can: 'delete',
        available: ((elem) => advancedToolStatsAvailable(elem)),
      },
      studies: {
        label: 'studies',
        link: '/studies',
        matcher: 'stud',
        icon: {
          name: 'file-text2',
        },
        icon2: BurstMode,
        available: ((elem) => elem.menuOptionStudies),
      },
      manageStudies: {
        label: 'manage.studies',
        link: '/work-studies',
        matcher: 'work-stud',
        icon: {
          name: 'file-text2',
        },
        icon2: InsertDriveFileTwoTone,
        available: ((elem) => elem.menuOptionStudies),
      },
      files: {
        label: 'files',
        link: '/files',
        matcher: 'file',
        icon: {
          name: 'file-text2',
        },
        icon2: NoteTwoTone,
        available: ((elem) => elem.menuOptionFiles),
      },
      settings: {
        label: 'settings',
        link: '/hospital/settings/edit',
        matcher: 'settings',
        icon: {
          name: 'settings',
        },
        icon2: Settings,
        can: 'manage',
        available: ((elem) => elem.menuOptionSettings),
      },
    },
    settings: {
      general: {
        label: 'hospital.settings.general',
        link: '/hospital/settings/edit',
        matcher: 'settings/edit',
      },
      menu: {
        label: 'hospital.settings.option',
        link: '/hospital/settings/option',
        matcher: 'settings/option',
      },
      team: {
        label: 'hospital.settings.team',
        link: '/hospital/settings/team',
        matcher: 'settings/team',
      },
      group: {
        label: 'hospital.settings.group',
        link: '/hospital/settings/group',
        matcher: 'settings/group',
      },
      patients: {
        label: 'hospital.settings.patients',
        link: '/hospital/settings/patients',
        matcher: 'settings/patients',
      },
      back: {
        label: 'back.to.dashboard',
        link: '/dashboard',
        matcher: 'dashboard',
      },
    },
  },
  provider: {
    advancedTools: {
      label: 'advanced.tools',
      link: '/advanced-tools',
      matcher: 'advanced-tools',
      icon: {
        name: 'wrench',
      },
    },
  },
  user: {
    settings: {
      profile: {
        label: 'user.settings.profile',
        link: '/user/settings/edit',
        matcher: 'settings/edit',
      },
      plan: {
        label: 'user.settings.plan',
        link: '/user/settings/plan',
        matcher: 'settings/plan',
      },
      back: {
        label: 'back.to.hospital',
        link: '/dashboard',
        matcher: 'dashboard',
      },
    },
  },
};
