import i18n from 'i18next';
import { LocaleList } from './LocaleUtils';
import { PlatformUpdateList } from './PlatformUpdateList';
import { dateParse } from './dateTimeUtils';

// Set how many days the information about the platform will be displayed after login
const expirationDaysForPlatformNews = 15;

// The number of days is converted to milliseconds to compare with current time
const expirationTime = (days) => (days * 1000 * 60 * 60 * 24);

// Checks the user language and returns the platform updates list
const mapPlatformUpdateList = (obj) => {
  const availableLanguages = [];

  // Search if valid locales are in news description and set them as available
  LocaleList.forEach((locale) => { if (locale.value in obj.description) { availableLanguages.push(locale.value); } });

  // In case no language is matched value NULL is returned
  if (availableLanguages.length === 0) {
    return null;
  }

  // Set description text with the selected user language, if not, with the first available language
  const description = obj.description[availableLanguages.includes(i18n.language) ? i18n.language : availableLanguages[0]];

  return {
    id: obj.id,
    date: dateParse(new Date(obj.date)),
    description,
  };
};

// Set the last time PlatformUpdate modal pop-up has been seen by user
export const setLocalStorageReadNews = () => {
  if (typeof (Storage) === 'undefined') {
    return;
  }
  localStorage.setItem('platformUpdateCheckedDate', new Date().valueOf());
};

// Get the last time PlatformUpdate modal pop-up has been seen by user
const getLocalStorageReadNews = () => {
  if (typeof (Storage) === 'undefined') {
    return 0;
  }
  if (!localStorage.platformUpdateCheckedDate) {
    return 0;
  }
  return localStorage.platformUpdateCheckedDate;
};

// Map the news that should be displayed filtered by the expiration days threshold
const selectNews = (list, days) => {
  const news = [];
  const currentDate = new Date().valueOf();

  // Every item date is checked and only added to the array if newer than expiration time
  list.forEach((obj) => {
    const newsDate = new Date(obj.date).valueOf();
    if ((newsDate + expirationTime(days)) > currentDate) {
      if (newsDate > getLocalStorageReadNews()) {
        news.push(mapPlatformUpdateList(obj));
      }
    }
  });

  return news;
};

export const publishablePlatformUpdates = selectNews(PlatformUpdateList, expirationDaysForPlatformNews);
export const displayUpdateNews = publishablePlatformUpdates.length > 0;
