import i18n from 'i18next';

export const LocaleList = [
  { id: 1, value: 'en', label: 'EN' },
  { id: 2, value: 'es', label: 'ES' },
];

export const getLocaleDateFormat = (weekday = false) => {
  const weekdayInfo = weekday ? ' (dddd)' : '';
  const formats = {
    en: {
      field: 'MM / dd / yyyy',
      placeholder: 'mm / dd / yyyy',
      format: 'MM/DD/YYYY',
      label: `MMM Do, YYYY${weekdayInfo}`,
      weekdayParentheses: 'MMM Do, YYYY (dddd)',
      weekdayComma: 'dddd, MMM Do YYYY',
    },
    es: {
      field: 'dd / MM / yyyy',
      placeholder: 'dd / mm / aaaa',
      format: 'DD/MM/YYYY',
      label: `D MMM YYYY${weekdayInfo}`,
      weekdayParentheses: 'D MMM YYYY (dddd)',
      weekdayComma: 'dddd, D MMM YYYY',
    },
  };
  return formats[i18n.language] || formats.en;
};
