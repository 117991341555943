import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from './App';

import Sidebar from '../components/Sidebar/Sidebar';
import { ContentWrapper } from './Common/styled/ContentWrapper';
import Header from './Header/Header';
import { MainContainer } from './Common/styled/MainContainer';
import { LicenseWarning } from './Common/LicenseWarning';

export default ({ children, type }) => {
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);
  const { fixSidebar, licenseWarning, visibleSidebar } = useSelector((state) => state.userInterface);

  const handleDrawerToggle = () => {
    dispatch({ type: 'SET_VISIBILITY_FILTER', filter: { visibleSidebar: false, visibleWorkspaces: false } });
  };

  const handleSwitch = () => {
    dispatch({ type: 'SET_VISIBILITY_FILTER', filter: { visibleSidebar: false, visibleWorkspaces: false, fixSidebar: !fixSidebar } });

    if (fixSidebar) setTimeout(() => setHover(false), 350);
  };

  return (
    <App>
      <Sidebar
        type={type}
        visibleSidebar={visibleSidebar}
        fixSidebar={fixSidebar}
        handleDrawerToggle={handleDrawerToggle}
        handleSwitch={handleSwitch}
        hover={hover}
        setHover={setHover}
      />
      <ContentWrapper fixSidebar={fixSidebar}>
        <Header type={type} fixSidebar={fixSidebar} />
        <MainContainer>
          {licenseWarning && <LicenseWarning />}
          {children}
        </MainContainer>
      </ContentWrapper>
    </App>
  );
};
