import React from 'react';
import i18n from 'i18next';
import moment from 'moment-timezone';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import { getFormattedDate, getFormattedDateTime, getFormattedTime } from '../../utils/dateTimeUtils';

export const FromNow = ({ date, years, parentheses, bold }) => {
  let formattedDate;
  const momentDate = moment(date).utc();
  momentDate.locale(i18n.language);

  if (years && typeof years === 'number' && moment().subtract(years, 'years') > momentDate) {
    formattedDate = momentDate.format('L');
  } else {
    formattedDate = momentDate.fromNow();
  }
  formattedDate = bold ? <b>{ formattedDate }</b> : formattedDate;

  if (parentheses) {
    return (
      <span>
        (
        {formattedDate}
        )
      </span>
    );
  }

  return <span>{ formattedDate }</span>;
};

export const LocaleDate = (props) => (
  <span>{` ${getFormattedDate(props)} `}</span>
);

export const LocaleTime = (props) => (
  <span>{` ${getFormattedTime(props)} `}</span>
);

export const LocaleDateTime = (props) => (
  <span>{` ${getFormattedDateTime(props)} `}</span>
);
