export const getCurrentPatientUserHospital = (node, user) => {
  if (!node) return null;
  if (!node.userPatients) return null;
  if (!user) return null;
  const match = node.userPatients.filter((patient) => patient.uuid === user.patientUuid);
  if (!match[0]) return null;

  return match[0].hospital;
};

export const getUserInterface = () => {
  const role = (process.env.APPLICATION_USER_INTERFACE || 'doctor').toLowerCase();

  if (role !== 'patient') {
    return 'doctor';
  }
  return role;
};
