export const PlatformUpdateList = [
  {
    id: 'AHP-92',
    date: '2021-02-15',
    description: {
      en: 'Enabled option to delete medical cases files and hospital files',
      es: 'Habilitada opción para borrar archivos de casos médicos y archivos de hospitales',
    },
  },
  {
    id: 'AHP-54',
    date: '2021-02-22',
    description: {
      en: 'Legal conditions according to GDPR in register page',
      es: 'Condiciones legales de conformidad con la LGPD en página de registro',
    },
  },
  {
    id: 'AHP-46',
    date: '2021-02-22',
    description: {
      en: 'Enabled option to delete study reports',
      es: 'Habilitada opción para borrar informes de estudios',
    },
  },
  {
    id: 'AHP-93',
    date: '2021-02-22',
    description: {
      en: 'Enabled option to delete a Hospital and all the elements that depend on it',
      es: 'Habilitada opción para borrar un Hospital y todos los elementos que dependen del mismo',
    },
  },
  {
    id: 'AHP-110',
    date: '2021-02-22',
    description: {
      en: 'Same users declared as hospital team members in different Hospitals do not consume billing plan user crédits',
      es: 'Un mismo usuario declarado como miembro de equipo de diferentes Hospitales no consume créditos de la suscripción de pago',
    },
  },
  {
    id: 'AHP-98',
    date: '2021-02-22',
    description: {
      en: 'Information about last release updates in dashboard',
      es: 'Información sobre las últimas actualizaciones en tablero',
    },
  },
  {
    id: 'AHP-106',
    date: '2021-03-05',
    description: {
      en: 'Patient info now also includes user email address',
      es: 'La ficha de paciante incluye ahora la dirección de email del usuario',
    },
  },
  {
    id: 'AHP-74',
    date: '2021-03-15',
    description: {
      en: 'List of all Hospital Patients available in the Hospital Management section',
      es: 'Lista de todos los Pacientes del Hospital habilitada en la sección de Gestión de Hospital',
    },
  },
  {
    id: 'AHP-120',
    date: '2021-03-16',
    description: {
      en: 'Doctor user being not Hospital Manager has now granted access to manage Hospital Files and Dicom Studies',
      es: 'Usuario doctor no administrador del Hospital tiene ahora derecho para gestionar Archivos y Estudios Dicom del Hospital',
    },
  },
  {
    id: 'AHP-136',
    date: '2021-06-30',
    description: {
      en: 'Different comment scope in Medical Cases: comments only available for Medical staff and comments to interact with Patients',
      es: 'Diferente ámbito de comentarios en Casos Médicos: comentarios sólo disponibles para personal Médico y  para interactuar con Pacientes',
    },
  },
];
