import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, MenuItem, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { GetValueToPath } from '../utils/functions';

const useStyles = makeStyles((theme) => ({
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Muli',
      color: '#606060',
    },
    '& .expanded': {
      display: 'none',
    },
    '& .collapsed': {
      display: 'block !important',
    },
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const TextFieldSelectUI = (
  {
    name,
    label,
    options,
    onClick,
    props,
    variant = 'standard',
    disabled = false,
    classes = useStyles(),
    disableunderline = 'false',
    type = 'standard',
    multiple = 'false',
  },
) => {
  const { values, handleChange, errors } = props;
  const { t } = useTranslation();
  const { select } = useStyles();
  const styles = classes ? `${select} ${classes.root}` : select;
  const value = GetValueToPath(values, name) || '';

  const getDisplayOption = (i) => {
    switch (type) {
      case 'language':
        return (
          <>
            <Box className="collapsed" style={{ display: 'none' }}>
              {i}
            </Box>
            <Box className="expanded">
              {t(`i18n.${i.toLowerCase()}`)}
            </Box>
          </>
        );
      default:
        return t(i);
    }
  };

  return (
    <TextField
      select
      variant={variant}
      label={t(label)}
      name={name}
      id={name}
      value={value}
      onChange={handleChange}
      className={styles}
      helperText={errors[name] && errors[name]}
      disabled={disabled}
      FormHelperTextProps={{
        classes: {
          root: classes && classes.helperTextRoot,
        },
      }}
      InputProps={{ disableunderline }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} onClick={() => onClick && onClick(option.value)}>
          {getDisplayOption(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
};
